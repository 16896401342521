<template>
  <v-container>
    <v-skeleton-loader
      type="table"
      v-if="!data_loaded"
      class="mt-10"
    ></v-skeleton-loader>
    <!--/ sekeletion -->



  

    <v-card elevation="0" class="mt-10" v-if="data_loaded">

      <v-card-text>
        <OrderFilter @update_orders="($event)=> orders = Object.assign([], $event)"></OrderFilter>
      </v-card-text>
      <v-card-title class="font-weight-bold">
        <h4>الطلبات</h4>

      </v-card-title>
      <v-data-table
        :footer-props="$store.state.footer_props"
        :items="orders"
        :headers="headers"
        :mobile-breakpoint="0"
      >
      <template v-slot:[`item.customer.name`] ="{item}">
        <v-btn text  color="blue"  :to="`/customerOrders?id=${item.customer.id}`">
          {{item.customer.name}}
        </v-btn>
      </template>
      <template v-slot:[`item.status`]="{item}">
        <v-chip color="#e58200" x-small v-if="item.status == 0" class='white--text' >
            جديد
        </v-chip>
        <v-chip  x-small color="#644ae1" v-if="item.status == 1" class='white--text'>
            قيد التحضير
        </v-chip>
        <v-chip color="#202020" v-if="item.status == 2" x-small class='white--text'>
            تم التجهيز
        </v-chip>
        <v-chip color="success" v-if="item.status == 3" x-small >
          تم التجهيز
        </v-chip>
        <v-chip color="red" v-if="item.status == 4" class='white--text'  x-small >
            ملغي
        </v-chip>
      </template>
      <template v-slot:[`item.category_name`]="{ item }">
        {{item.category_name[0].name}}
        </template>
        <!--/ logo -->
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn @click="show_order(item)"  small icon>
            <v-icon small>visibility</v-icon>
          </v-btn>
        </template>
        <!--/ actions -->
      </v-data-table>
      <!--/ datatable -->
    </v-card>
    <!--/ card -->



    <v-snackbar color="success" left v-model="snackbar_success">
      <p>تم حذف المحل</p>
      <template v-slot:action>
        <v-icon color="white" class="ms-3">check_circle</v-icon>
      </template>
    </v-snackbar>
  </v-container>
  <!--/ container -->
</template>

<script>
import OrderFilter from "@/components/Admin/orders/OrderFilter";
export default {
  name: "employees",

  data() {
    return {
      dialog:false,
      snackbar_success: false,
      delete_dialog: false,
      delete_loader: false,
      delete_id: null,
      data_loaded: true,
      orders: [],
      
      headers: [
        {
          value: "id",
          text: " رقم الطلب",
          align: "center",
        },
        {
          value: "created_at",
          text: "تاريخ الطلب",
          align: "center",
        },
        {
          value: "status",
          text: "حالة الطلب",
          align: "center",
        },
        {
          value: "customer.name",
          text: "الزبون",
          align: "center",
        },
        {
          value: "accept_time",
          text: "مدة القبول",
          align: "center",
        },
        {
          value: "preparation_time",
          text: "مدة التحضير",
          align: "center",
        },
        {
          value: "recived_time",
          text: "مدة التوصيل او الأستلام",
          align: "center",
        },
        {
          value: "actions",
          text: "اجراءات",
          align: "center",
        },
      ],
    };
  },
  watch:{

  },
  components:{
    OrderFilter
  },
  methods: {
    show_order(item) {
      this.item = item;
      this.dialog = true;
    },

  },
  mounted() {
  },
};
</script>
