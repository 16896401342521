<template>
  <v-row>
    <v-col cols="6" sm="4">
      <v-select
        :items="apps"
        multiple
        v-model="filter.app"
        label="المصدر"
        solo
      ></v-select>
    </v-col>
    <!--/col -->
    <v-col cols="6" sm="4">
      <v-select
        multiple
        :items="stores"
        item-text="store_name"
        item-value="id"
        label="المحلات"
        solo
      ></v-select>
    </v-col>
    <!--/col -->

    <v-col cols="6" sm="4">
      <v-select
        multiple
        :items="status"
        item-text="text"
        item-value="value"
        label="الحالة"
        solo
        v-model="filter.status"
      ></v-select>
    </v-col>
    <!--/col -->
    <v-col cols="6" sm="4">
      <v-select
        :items="payment_methods"
        multiple
        v-model="filter.payment_methods"
        label="طريقة الدفع"
        solo
      ></v-select>
    </v-col>
    <!--/col -->
    <v-col cols="6" sm="4">
      <v-select
        :items="delivery_methods"
        v-model="filter.delivery_methods"
        label="طريقة التوصيل"
        solo
        item-text="text"
        item-value="value"
        multiple
      ></v-select>
    </v-col>
    <!--/col -->
  </v-row>
  <!--/ row -->
</template>

<script>
export default {
  name: "OrderFilter",
  data() {
    return {
      apps: ["menu", "app1"],
      stores: [],
      status: [
        {
          value: 0,
          text: "جديد",
        },
        {
          value: 1,
          text: "قيد التحضير",
        },
        {
          value: 2,
          text: "جاهز للأستلام",
        },
        {
          value: 3,
          text: "تم التسليم",
        },
        {
          value: 4,
          text: "ملغي",
        },
      ],
      payment_methods: ["cash", "visa"],
      delivery_methods: [
        {
          text: "توصيل ذاتي",
          value: "hand_delivery",
        },
        {
          text: "توصيل للمنزل",
          value: "home_delivery",
        },
      ],
      filter: {
        app: [],
        stores: [],
        status: [],
        payment_methods: [],
        delivery_methods: [],
      },
    };
  },
  methods: {
    get_stores() {
      this.$store
        .dispatch("public__request", {
          config: {
            url: "stores_select",
            method: "get",
          },
        })
        .then((res) => {
          this.stores = Object.assign([], res.data.data);
        });
    },
    filter_orders() {
      this.$store
        .dispatch("public__request", {
          config: {
            url: "a_orders",
            method: "post",
          },
          data: this.filter,
        })
        .then((res) => {

            this.$emit("update_orders", res.data.data)

          console.log(res.data.data);
        })
        .catch((err) => {
          console.log(err.response  );
        });
    },
  },
  created() {
    this.get_stores();
    this.filter_orders();
  },
};
</script>
