var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[(!_vm.data_loaded)?_c('v-skeleton-loader',{staticClass:"mt-10",attrs:{"type":"table"}}):_vm._e(),(_vm.data_loaded)?_c('v-card',{staticClass:"mt-10",attrs:{"elevation":"0"}},[_c('v-card-text',[_c('OrderFilter',{on:{"update_orders":function ($event){ return _vm.orders = Object.assign([], $event); }}})],1),_c('v-card-title',{staticClass:"font-weight-bold"},[_c('h4',[_vm._v("الطلبات")])]),_c('v-data-table',{attrs:{"footer-props":_vm.$store.state.footer_props,"items":_vm.orders,"headers":_vm.headers,"mobile-breakpoint":0},scopedSlots:_vm._u([{key:"item.customer.name",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"text":"","color":"blue","to":("/customerOrders?id=" + (item.customer.id))}},[_vm._v(" "+_vm._s(item.customer.name)+" ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status == 0)?_c('v-chip',{staticClass:"white--text",attrs:{"color":"#e58200","x-small":""}},[_vm._v(" جديد ")]):_vm._e(),(item.status == 1)?_c('v-chip',{staticClass:"white--text",attrs:{"x-small":"","color":"#644ae1"}},[_vm._v(" قيد التحضير ")]):_vm._e(),(item.status == 2)?_c('v-chip',{staticClass:"white--text",attrs:{"color":"#202020","x-small":""}},[_vm._v(" تم التجهيز ")]):_vm._e(),(item.status == 3)?_c('v-chip',{attrs:{"color":"success","x-small":""}},[_vm._v(" تم التجهيز ")]):_vm._e(),(item.status == 4)?_c('v-chip',{staticClass:"white--text",attrs:{"color":"red","x-small":""}},[_vm._v(" ملغي ")]):_vm._e()]}},{key:"item.category_name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.category_name[0].name)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.show_order(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("visibility")])],1)]}}],null,true)})],1):_vm._e(),_c('v-snackbar',{attrs:{"color":"success","left":""},scopedSlots:_vm._u([{key:"action",fn:function(){return [_c('v-icon',{staticClass:"ms-3",attrs:{"color":"white"}},[_vm._v("check_circle")])]},proxy:true}]),model:{value:(_vm.snackbar_success),callback:function ($$v) {_vm.snackbar_success=$$v},expression:"snackbar_success"}},[_c('p',[_vm._v("تم حذف المحل")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }